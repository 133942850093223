export const FURNITURE_DEFAULT_INVENTORY = {
  301: {
    label: "Beds",
    icon: ["custom", "item-bed"],
    title: "Bed"
  },
  302: {
    label: "Televisions",
    icon: ["custom", "item-tv"],
    title: "Television"
  },
  304: {
    label: "Tables",
    icon: ["custom", "item-table"],
    title: "Table"
  },
  305: {
    label: "Clothing",
    icon: ["custom", "item-clothing"],
    title: "Clothing"
  },
  306: {
    label: "Boxes",
    icon: ["custom", "item-box"],
    title: "Box"
  },
  307: {
    label: "Wardrobes",
    icon: ["custom", "item-wardrobe"],
    title: "Wardrobe"
  },
  309: {
    label: "Chairs",
    icon: ["custom", "item-chair"],
    title: "Chair"
  },
  310: {
    label: "Sofas",
    icon: ["custom", "item-sofa"],
    title: "Sofa"
  }
}

export const FURNITURE_DEFAULT_INVENTORY_DESKTOP = {
  310: {
    label: "Sofas",
    title: "Sofa",
    left: true
  },
  307: {
    label: "Wardrobes",
    title: "Wardrobe",
    left: true
  },
  306: {
    label: "Boxes",
    title: "Box",
    left: true
  },
  301: {
    label: "Beds",
    title: "Bed",
    left: false
  },
  304: {
    label: "Tables",
    title: "Table",
    left: false
  },
  302: {
    label: "TVs",
    title: "Television",
    left: false
  },
  309: {
    label: "Chairs",
    title: "Chair",
    left: false
  }
}

export const FURNITURE_DEFAULT_INVENTORY_LEFT_DESKTOP = {
  310: {
    label: "Sofas",
    title: "Sofa",
    left: true
  },
  307: {
    label: "Wardrobes",
    title: "Wardrobe",
    left: true
  },
  306: {
    label: "Boxes",
    title: "Box",
    left: true
  }
}

export const FURNITURE_DEFAULT_INVENTORY_RIGHT_DESKTOP = {
  301: {
    label: "Beds",
    title: "Bed",
    left: false
  },
  304: {
    label: "Tables",
    title: "Table",
    left: false
  },
  302: {
    label: "TVs",
    title: "Television",
    left: false
  },
  309: {
    label: "Chairs",
    title: "Chair",
    left: false
  }
}

export const FURNITURE_OTHER_TYPE = {
  label: "Custom item",
  icon: ["custom", "item-other"],
  title: "Add Your Own Item"
}

export const FURNITURE_OTHER_TYPE_DESKTOP = {
  name: "Add Custom Item",
  quantity: 0
}

export const ITEM_TYPE_BY_TITLE = {
  // Search terms below
  Mattress: 301,
  TV: 302,
  Desk: 304,
  "Small Bag": 305,
  "Big Bag": 305,
  Clothes: 305,
  Cupboard: 307,
  Couch: 310,
  Settee: 310,
  // General types below
  Bed: 301,
  Television: 302,
  Table: 304,
  Clothing: 305,
  Box: 306,
  Wardrobe: 307,
  Chair: 309,
  Sofa: 310
}

export const ITEM_CATEGORY_PROMO = {
  key: "Promoted",
  items: [
    {
      quantity: 0,
      dimensions: "approx 55 x 55 x 55 cm",
      item_id: 845,
      name: "Large Box",
      category_id: 1,
      original_width: 55,
      original_depth: 55,
      original_height: 55,
      original_weight: 10,
      dimension_unit: "cm",
      weight_unit: "kg",
      fake_id: "86633c67abdb634dd8127be6be600e41",
      is_admin: true
    },
    {
      quantity: 0,
      item_id: 1112,
      name: "Two Seater Sofa",
      category_id: 1,
      original_width: 83,
      original_depth: 151,
      original_height: 89,
      original_weight: 42,
      dimension_unit: "cm",
      weight_unit: "kg",
      fake_id: "50d6f70c7c55abd011443da27ae39ea0",
      is_admin: true
    },
    {
      quantity: 0,
      item_id: 1083,
      name: "Three Seater Sofa",
      category_id: 1,
      original_width: 84,
      original_depth: 196,
      original_height: 89,
      original_weight: 48,
      dimension_unit: "cm",
      weight_unit: "kg",
      fake_id: "faac390d4b4afd6c3e7b933855523ba4",
      is_admin: true
    },
    {
      quantity: 0,
      dimensions: "approx 50 x 50 x 50 cm",
      item_id: 884,
      name: "Medium Box",
      category_id: 1,
      original_width: 50,
      original_depth: 50,
      original_height: 50,
      original_weight: 8,
      dimension_unit: "cm",
      weight_unit: "kg",
      fake_id: "0e7df3dd2cb13337798b9f6b6e51cadd",
      is_admin: true
    },
    {
      quantity: 0,
      item_id: 693,
      name: "Double Bed & Mattress",
      category_id: 1,
      original_width: 141,
      original_depth: 56,
      original_height: 180,
      original_weight: 44,
      dimension_unit: "cm",
      weight_unit: "kg",
      fake_id: "f393c47f1a1fa3088c1cd7f96f959073",
      is_admin: true
    },
    {
      quantity: 0,
      item_id: 518,
      name: "Armchair",
      category_id: 1,
      original_width: 108,
      original_depth: 100,
      original_height: 108,
      original_weight: 41,
      dimension_unit: "cm",
      weight_unit: "kg",
      fake_id: "62facfadf7d90382032aa0b639246fa5",
      is_admin: true
    },
    {
      quantity: 0,
      item_id: 26183,
      name: "Double Wardrobe",
      category_id: 1,
      original_width: 115,
      original_depth: 60,
      original_height: 180,
      original_weight: 90,
      dimension_unit: "cm",
      weight_unit: "kg",
      fake_id: "bbb9fec41d66295e3526978d9144a219",
      is_admin: true
    },
    {
      quantity: 0,
      item_id: 856,
      name: 'Large Television/TV (Greater than 40")',
      category_id: 1,
      original_width: 103,
      original_depth: 20,
      original_height: 70,
      original_weight: 25,
      dimension_unit: "cm",
      weight_unit: "kg",
      fake_id: "1458392893a10d282365ee4b18a76e54",
      is_admin: true
    }
  ]
}

export const ITEM_CATEGORY_PROMO_DESKTOP = {
  key: "Popular",
  items: [
    {
      quantity: 0,
      dimensions: "approx 55 x 55 x 55 cm",
      item_id: 845,
      name: "Large Box",
      category_id: 1,
      original_width: 55,
      original_depth: 55,
      original_height: 55,
      original_weight: 10,
      dimension_unit: "cm",
      weight_unit: "kg",
      fake_id: "86633c67abdb634dd8127be6be600e41",
      is_admin: true
    },
    {
      quantity: 0,
      dimensions: "approx 50 x 50 x 50 cm",
      item_id: 884,
      name: "Medium Box",
      category_id: 1,
      original_width: 50,
      original_depth: 50,
      original_height: 50,
      original_weight: 8,
      dimension_unit: "cm",
      weight_unit: "kg",
      fake_id: "0e7df3dd2cb13337798b9f6b6e51cadd",
      is_admin: true
    },
    {
      quantity: 0,
      item_id: 28424,
      name: "Suitcase",
      category_id: 1,
      original_width: 35,
      original_depth: 55,
      original_height: 82,
      original_weight: 10,
      dimension_unit: "cm",
      weight_unit: "kg",
      fake_id: "2fcba5c9f719ad5d68fa790259ffe39a",
      is_admin: true
    },
    {
      quantity: 0,
      item_id: 518,
      name: "Armchair",
      category_id: 1,
      original_width: 108,
      original_depth: 100,
      original_height: 108,
      original_weight: 41,
      dimension_unit: "cm",
      weight_unit: "kg",
      fake_id: "62facfadf7d90382032aa0b639246fa5",
      is_admin: true
    },
    {
      quantity: 0,
      item_id: 1083,
      name: "Three Seater Sofa",
      category_id: 1,
      original_width: 84,
      original_depth: 196,
      original_height: 89,
      original_weight: 48,
      dimension_unit: "cm",
      weight_unit: "kg",
      fake_id: "faac390d4b4afd6c3e7b933855523ba4",
      is_admin: true
    },
    {
      quantity: 0,
      item_id: 693,
      name: "Double Bed & Mattress",
      category_id: 1,
      original_width: 141,
      original_depth: 56,
      original_height: 180,
      original_weight: 44,
      dimension_unit: "cm",
      weight_unit: "kg",
      fake_id: "f393c47f1a1fa3088c1cd7f96f959073",
      is_admin: true
    }
  ]
}

export const DEFAULT_LENGTH_UNIT = "cm"
export const DEFAULT_WEIGHT_UNIT = "kg"

export const WEIGHT_OPTIONS = [
  { name: "kg", value: "kg" },
  { name: "lbs", value: "lbs" }
]
export const LENGTH_OPTIONS = [
  { name: "cm", value: "cm" },
  { name: "m", value: "m" },
  { name: "in", value: "in" },
  { name: "ft", value: "ft" },
  { name: "mm", value: "mm" }
]

export const ITEM_SEARCH_LIMIT: number = 2
export const DESKTOP_SEARCH_ITEMS_COUNT_TO_VIEW: number = 7
export const DESKTOP_SUMMARY_ITEMS_COUNT_TO_VIEW: number = 6
export const DESKTOP_DROPDOWN_MAX_HEIGHT = 428
