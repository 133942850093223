import { _SET_RESPONSE } from "~/store/item-category/mutations"
import { ITEM_TYPE_BY_TITLE } from "~/constants/item"
import { findItemTypeByName, getCustomItemDimensions } from "~/utils/item"

export const CATEGORY_ITEMS = "categoryItems"
export const INCREASE_ITEM_CATEGORY = "increaseItemCategory"
export const DECREASE_ITEM_CATEGORY = "decreaseItemCategory"

export default {
  [CATEGORY_ITEMS]({ state, commit }, categoryParams) {
    const itemCategory: ItemCategory = {
      key: categoryParams.key,
      items: categoryParams.items.map((item: Item) => {
        item.type = ITEM_TYPE_BY_TITLE[categoryParams.key] ?? findItemTypeByName(item.name)
        // 306 held for Boxes in FURNITURE_DEFAULT_INVENTORY & in ..._DESKTOP
        // FURNITURE_OTHER_TYPE_DESKTOP should be excluded
        if (item.type === 306 && item.dimension_unit) {
          item.dimensions = "approx " + getCustomItemDimensions(item)
        }
        return item
      })
    }

    commit(_SET_RESPONSE, itemCategory)
  }
}
