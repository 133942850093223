// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/img/404_background.jpg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".errorPage_B\\+gw-{padding:150px 0;text-align:center;background-size:cover;background-position-y:50%;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");color:#fff;margin:-40px 0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorPage": "errorPage_B+gw-"
};
module.exports = ___CSS_LOADER_EXPORT___;
