import { Plugin } from "@nuxt/types"
import Vue from "vue"
import Tick from "@pqina/flip"

Vue.prototype.$tick = Tick

const flipPlugin: Plugin = (_, inject) => {
  inject("tick", Tick)
}

export default flipPlugin
