import { configure } from "vee-validate"

export default function ({ app }) {
  const config = {
    // mode: "eager", //Is a combination of aggressive and lazy - seems not working perfectly
    classes: {
      valid: "is-valid",
      invalid: "is-invalid"
    }
    // ,
    // defaultMessage: (field, values) => {
    //   // override the field name
    //   values._field_ = app.i18n.t(`fields.${field}`)
    //
    //   if (
    //     app.i18n.t(`validation.${field}.${values._rule_}`) !==
    //     `validation.${field}.${values._rule_}`
    //   ) {
    //     return app.i18n.t(`validation.${field}.${values._rule_}`, values)
    //   }
    //
    //   return app.i18n.t(`validation.${values._rule_}`, values)
    // }
  }

  configure(config)
}
