export const GET_PROPERTY_TYPES = "getPropertyTypes"
export const GET_PROPERTY_TYPES_HOUSE = "getPropertyTypesHouse"
export const GET_PROPERTY_TYPES_FLAT = "getPropertyTypesFlat"
export const GET_FLOORS = "getFloors"

export default {
  [GET_PROPERTY_TYPES](state: any) {
    return state.propertyTypes
  },

  [GET_PROPERTY_TYPES_HOUSE](state: any) {
    return state.propertyTypesHouse
  },

  [GET_PROPERTY_TYPES_FLAT](state: any) {
    return state.propertyTypesFlat
  },

  [GET_FLOORS](state: any) {
    return state.floors
  }
}
