import { library } from "@fortawesome/fontawesome-svg-core"

// TODO: build only the selected icons into the final package somehow
//      maybe a webpack plugin

/*
 ** Brands (fab)
 */

import { faGooglePlusG, faTwitter, faFacebookF, faEbay } from "@fortawesome/free-brands-svg-icons"

/*
 ** Light (fal)
 */

import {
  faAngleUp as falAngleUp,
  faAngleDown as falAngleDown,
  faAngleLeft as falAngleLeft,
  faAngleRight as falAngleRight,
  faPersonCarry as falPersonCarry,
  faPeopleCarry as falPeopleCarry,
  faTimes as falTimes,
  faPlus as falPlus,
  faMinus as falMinus,
  faCircle as falCircle,
  faCreditCard as falCreditCard,
  faSquare as falSquare,
  faCheckSquare as falCheckSquare,
  faEnvelope as falEnvelope,
  faCommentDots as falCommentDots,
  faMapMarkerAlt as falMapMarkerAlt,
  faDesktopAlt as falDesktopAlt,
  faLaugh as falLaugh,
  faFrown as falFrown,
  faMeh as falMeh
} from "@fortawesome/pro-light-svg-icons"

/*
 ** Regular (far)
 */

import {
  faWeightHanging,
  faPauseCircle,
  faClipboardListCheck,
  faFileCheck,
  faAnkh,
  faSync,
  faCheckCircle,
  faExclamationTriangle,
  faTruck,
  faUser,
  faPaperPlane,
  faCircle,
  faTimesCircle,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faAngleDown,
  faCouch,
  faHome,
  faCarSide,
  faMotorcycle,
  faMusic,
  faBox,
  faCabinetFiling,
  faTire,
  faTruckContainer,
  faShip,
  faChairOffice,
  faRecycle,
  faIndustryAlt,
  faPaw,
  faPersonCarry,
  faBed,
  faUtensils,
  faTvRetro,
  faSuitcase,
  faChair,
  faPlusCircle,
  faMinusCircle,
  faPlusSquare,
  faMinusSquare,
  faPencil,
  faTrashAlt,
  faClock,
  faHandHoldingUsd,
  faThumbsUp,
  faFilePdf,
  faUsers,
  faLightbulbOn,
  faSquare,
  faCheckSquare,
  faStar,
  faMapMarkedAlt,
  faArrowAltUp,
  faArrowAltDown,
  faMale,
  faList,
  faAddressBook,
  faBoxUp,
  faCalendarAlt,
  faCalendarDay,
  faQuestionCircle,
  faInfoCircle,
  faExclamationCircle,
  faCalendarCheck,
  faCommentAltLines,
  faMoneyCheck,
  faMedal,
  faCrown,
  faCalendar,
  faSearch,
  faMoneyBillWaveAlt,
  faSlidersH,
  faSignOutAlt,
  faPlus,
  faMinus,
  faPlayCircle,
  faArrowSquareUp
} from "@fortawesome/pro-regular-svg-icons"

/*
 ** Solid (fas)
 */

import {
  faPhone as fasPhone,
  faCircle as fasCircle,
  faCheck as fasCheck,
  faExclamationTriangle as fasExclamationTriangle,
  faStar as fasStar,
  faTag as fasTag,
  faTimesCircle as fasTimesCircle,
  faBars as fasBars,
  faPen as fasPen,
  faCheckCircle as fasCheckCircle,
  faThumbsUp as fasThumbsUp,
  faQuestionCircle as fasQuestionCircle,
  faBox as fasBox,
  faCaretUp as fasCaretUp,
  faCaretDown as fasCaretDown,
  faCaretLeft as fasCaretLeft,
  faCaretRight as fasCaretRight,
  faPoundSign as fasPoundSign,
  faEdit as fasEdit,
  faCheckSquare as fasCheckSquare,
  faMobileAlt as fasMobileAlt,
  faSteeringWheel as fasSteeringWheel,
  faLocation as fasLocation,
  faMinus as fasMinus,
  faPlus as fasPlus,
  faMapMarker as fasMapMarker,
  faClock as fasClock,
  faCloudUploadAlt as fasCloudUploadAlt,
  faPause as fasPause,
  faPlay as fasPlay
} from "@fortawesome/pro-solid-svg-icons"

library.add(faGooglePlusG)
library.add(faTwitter)
library.add(faFacebookF)
library.add(faEbay)

library.add(falAngleUp)
library.add(falAngleDown)
library.add(falAngleLeft)
library.add(falAngleRight)
library.add(falPersonCarry)
library.add(falPeopleCarry)
library.add(falTimes)
library.add(falPlus)
library.add(falMinus)
library.add(falCircle)
library.add(falCreditCard)
library.add(falSquare)
library.add(falCheckSquare)
library.add(falEnvelope)
library.add(falCommentDots)
library.add(falMapMarkerAlt)
library.add(falDesktopAlt)
library.add(falLaugh)
library.add(falFrown)
library.add(falMeh)

library.add(faWeightHanging)
library.add(faArrowSquareUp)
library.add(faPauseCircle)
library.add(faSignOutAlt)
library.add(faMoneyBillWaveAlt)
library.add(faSlidersH)
library.add(faAnkh)
library.add(faClipboardListCheck)
library.add(faFileCheck)
library.add(faSync)
library.add(faCheckCircle)
library.add(faExclamationTriangle)
library.add(faTruck)
library.add(faUser)
library.add(faPaperPlane)
library.add(faCircle)
library.add(faTimesCircle)
library.add(faAngleLeft)
library.add(faAngleRight)
library.add(faAngleUp)
library.add(faAngleDown)
library.add(faCouch)
library.add(faHome)
library.add(faCarSide)
library.add(faMotorcycle)
library.add(faMusic)
library.add(faBox)
library.add(faCabinetFiling)
library.add(faTire)
library.add(faTruckContainer)
library.add(faShip)
library.add(faChairOffice)
library.add(faRecycle)
library.add(faIndustryAlt)
library.add(faPaw)
library.add(faPersonCarry)
library.add(faBed)
library.add(faUtensils)
library.add(faTvRetro)
library.add(faSuitcase)
library.add(faChair)
library.add(faPlusCircle)
library.add(faMinusCircle)
library.add(faPlusSquare)
library.add(faMinusSquare)
library.add(faPencil)
library.add(faTrashAlt)
library.add(faClock)
library.add(faHandHoldingUsd)
library.add(faThumbsUp)
library.add(faFilePdf)
library.add(faUsers)
library.add(faLightbulbOn)
library.add(faSquare)
library.add(faCheckSquare)
library.add(faStar)
library.add(faMapMarkedAlt)
library.add(faArrowAltUp)
library.add(faArrowAltDown)
library.add(faMale)
library.add(faList)
library.add(faAddressBook)
library.add(faBoxUp)
library.add(faCalendarAlt)
library.add(faCalendarDay)
library.add(faQuestionCircle)
library.add(faInfoCircle)
library.add(faExclamationCircle)
library.add(faCalendarCheck)
library.add(faCommentAltLines)
library.add(faMoneyCheck)
library.add(faMedal)
library.add(faCrown)
library.add(faCalendar)
library.add(faSearch)
library.add(faPlus)
library.add(faMinus)
library.add(faPlayCircle)

library.add(fasPhone)
library.add(fasCircle)
library.add(fasCheck)
library.add(fasExclamationTriangle)
library.add(fasStar)
library.add(fasTag)
library.add(fasTimesCircle)
library.add(fasBars)
library.add(fasPen)
library.add(fasCheckCircle)
library.add(fasThumbsUp)
library.add(fasQuestionCircle)
library.add(fasStar)
library.add(fasBox)
library.add(fasCaretUp)
library.add(fasCaretDown)
library.add(fasCaretLeft)
library.add(fasCaretRight)
library.add(fasPoundSign)
library.add(fasEdit)
library.add(fasCheckSquare)
library.add(fasMobileAlt)
library.add(fasSteeringWheel)
library.add(fasLocation)
library.add(fasMinus)
library.add(fasPlus)
library.add(fasMapMarker)
library.add(fasClock)
library.add(fasCloudUploadAlt)
library.add(fasPause)
library.add(fasPlay)
