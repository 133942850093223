export const GET_NODES = 'getNodes'
export const GET_PAGE = 'getPage'
export const GET_PAGE_INFO = 'getPageInfo'
export const GET_BREADCRUMBS = 'getBreadcrumbs'
export const GET_INVENTORY = 'getInventory'
export const GET_INVENTORY_DESKTOP = 'getInventoryDesktop'
export const GET_WINDOW_WIDTH = 'getWindowWidth'
export const GET_IS_SMALL_OR_MEDIUM = 'getIsSmallOrMedium'
export const GET_IS_EXTRA_LARGE = 'getIsExtraLarge'

export default {
  [GET_NODES]: (state) => state.nodes,
  [GET_PAGE]: (state) => state.page,
  [GET_PAGE_INFO]: (state) => state.pageInfo,
  [GET_BREADCRUMBS]: (state) => state.breadcrumbs,
  [GET_INVENTORY]: (state) => state.inventory,
  [GET_INVENTORY_DESKTOP]: (state) => state.inventoryDesktop,
  [GET_WINDOW_WIDTH]: (state) => state.windowWidth,
  [GET_IS_SMALL_OR_MEDIUM]: state =>
    state.windowWidth === undefined || (state.windowWidth && state.windowWidth < 992),
  [GET_IS_EXTRA_LARGE]: state => state.windowWidth && state.windowWidth >= 1200
}
