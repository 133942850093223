import { newTracker } from "@snowplow/browser-tracker"
import { WebVitalsPlugin } from '@snowplow/browser-plugin-web-vitals'


export default function (context) {
  if (!context.$config.snowplowIsEnabled) {
    return
  }
  newTracker("cms-content-tracker", context.$config.snowplowHost, {
    appId: "cms-content",
    platform: "web",
    plugins: [WebVitalsPlugin()]
  })
}
