import apiClient from "~/services/ApiService"
import { ApiResource } from "~/scripts/api-resource"

/* TODO When V4 furniture-defaults endpoint is updated we can get rid of furniture.default.variant.get */
const apiUrl = {
  searchItems: {
    get: "ng/item-search"
  },
  furniture: {
    default: {
      control: {
        get: "ng/inventory/furniture-defaults"
      },
      variant: {
        get: "ng/inventory/furniture-defaults?variant=variant"
      }
    }
  },
  houseMove: {
    default: {
      get: "ng/inventory/removal-default-items"
    },
    preset: {
      get: "ng/inventory/removal-preset"
    }
  }
}

export function searchItems(query, apiResource?: ApiResource): any | null {
  return apiClient.request({ url: apiUrl.searchItems.get, data: { query } }, apiResource)
}

export function getFurnitureDefaultInventory(query?, apiResource?: ApiResource): any | null {
  return apiClient.request(
    { url: apiUrl.furniture.default.variant.get, data: { query } },
    apiResource
  )
}

export function getHouseMoveDefaultInventory(query?, apiResource?: ApiResource): any | null {
  return apiClient.request({ url: apiUrl.houseMove.default.get, data: { query } }, apiResource)
}

export function getHouseMovePresetInventory(query?, apiResource?: ApiResource): any | null {
  return apiClient.request({ url: apiUrl.houseMove.preset.get, data: { query } }, apiResource)
}
