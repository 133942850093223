import Vue from "vue"
import { GET_FAKE_ID } from "~/store/listing/getters"

export const _SET_RESPONSE = "setResponse"
export const _UPDATE_ITEM_CATEGORY = "updateItemCategory"

export default {
  [_SET_RESPONSE](state: ItemCategory[], response: ItemCategory) {
    state.push(response)
  },
  [_UPDATE_ITEM_CATEGORY](state: ItemCategory[], itemCategory: ItemCategory) {
    const key: string = itemCategory.key
    const item: Item = itemCategory.items[0]
    const itemsIndex = state.findIndex(itemCategory => itemCategory.key === key)
    const items: Item[] = state[itemsIndex].items
    const index: number = items.findIndex(
      it => it.fake_id === item.fake_id && it.location_id === item.location_id
    )

    item.fake_id = GET_FAKE_ID(item)
    Vue.set(state[itemsIndex].items, index, item)
  }
}
