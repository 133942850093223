import Vue from "vue"
import { Store } from "vuex"

export const _SET_EXPERIMENTS_DATA = "setExperimentsData"

export default {
  [_SET_EXPERIMENTS_DATA](state: Experiments, experimentData: Map<string, any>) {
    state.experimentsData = experimentData
  }
}
