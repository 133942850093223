import { _SET_FW_TOKEN, _SET_USER } from "./mutations"

export const SET_FW_TOKEN = "setFwToken"
export const SET_USER = "setUser"

export default {
  [SET_FW_TOKEN]({ commit }, token: string) {
    commit(_SET_FW_TOKEN, token)
  },
  [SET_USER]({ commit }, user: Object) {
    commit(_SET_USER, user)
  }
}
