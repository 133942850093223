import { Context } from '@nuxt/types'

export default function ({ redirect, route }: Context) {
  if (route.query.preview) {
    const query = route.query
    if (route.params.slug) {
      query.slug = route.params.slug
    }

    return redirect({ path: '/preview', query })
  }
}
