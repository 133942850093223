import Vue from "vue"

Vue.filter("truncate", (val: string, n: number) => {
  return val.length > n ? val.substr(0, n) + "..." : val
})

Vue.filter("secondsToHours", (val, custom = false) => {
  if (typeof val === "undefined" || val === null) {
    return 0
  }

  const h = Math.floor(val / 3600)
  const m = Math.floor((val % 3600) / 60)
  const s = Math.floor((val % 3600) % 60)

  if (custom) {
    return { h, m, s }
  }

  const hDisplay = h > 0 ? h + (h === 1 ? " hour " : " hours ") : ""
  const mDisplay = m > 0 ? m + (m === 1 ? " min" : " mins") : ""

  return hDisplay + mDisplay
})

Vue.filter("metersTo", (val: number, unit: string = "") => {
  if (typeof val === "undefined" || val === null) {
    return ""
  }

  let correctValue: number = 0

  if (unit === "" || unit === "mile") {
    correctValue = val / 1609.344
  } else if (unit === "km") {
    correctValue = val / 1000
  }

  if (correctValue < 1) {
    return 1
  }

  return parseInt(String(correctValue))
})
