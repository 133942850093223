
import { Vue, Component } from "nuxt-property-decorator"

@Component({
  head() {
    return {
      title: '404',
    }
  },
})
export default class ErrorLayout extends Vue {
}
