import Vue, { DirectiveBinding } from "vue"

interface HTMLElementWithObserver extends HTMLElement {
  _observer?: IntersectionObserver
}

const observeVisibility = {
  inserted(
    el: HTMLElementWithObserver,
    binding: DirectiveBinding<(entry: IntersectionObserverEntry) => void>
  ) {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry && entry.isIntersecting) {
        binding.value(entry)
      }
    })
    el._observer = observer
    observer.observe(el)
  },
  unbind(el: HTMLElementWithObserver) {
    if (el._observer) {
      el._observer.disconnect()
    }
  }
}

Vue.directive("observe-visibility", observeVisibility)
