import {
  _SET_NODES,
  _SET_PAGE_INFO,
  _SET_PAGE,
  _SET_BREADCRUMBS,
  _SET_INVENTORY,
  _SET_INVENTORY_DESKTOP
} from "~/store/pages/mutations"
import { getPages, getPage } from "~/services/public/Pages"

export const LOAD_PAGES = "loadPages"
export const LOAD_PAGE = "loadPage"
export const SET_BREADCRUMBS = "setBreadcrumbs"
export const SET_INVENTORY = "setInventory"
export const SET_INVENTORY_DESKTOP = "setInventoryDesktop"
export const SET_WINDOW_WIDTH = "setWindowWidth"

export default {
  async [LOAD_PAGES]({ commit }, data) {
    if (!process.server) {
      return false
    }

    try {
      const response = await getPages(data.config, data.options)

      if (response.loading === false && response.data) {
        commit(_SET_NODES, response.data.pages.edges)
        commit(_SET_PAGE_INFO, response.data.pages.pageInfo)
      }

      return true
    } catch (err) {
      console.log(err)
      return err
    }
  },

  async [LOAD_PAGE]({ commit }, data) {
    if (!process.server) {
      return false
    }

    try {
      const response = await getPage(data.config, data.slug)

      if (response.loading === false && response.data) {
        commit(_SET_PAGE, response.data.contentNode)
      }

      return true
    } catch (err) {
      console.log(err)
      return err
    }
  },

  [SET_BREADCRUMBS]({ commit }, data) {
    try {
      commit(_SET_BREADCRUMBS, data.breadcrumbs)

      return true
    } catch (err) {
      console.log(err)
      return err
    }
  },

  [SET_INVENTORY]({ commit }, inventory: boolean | null) {
    commit(_SET_INVENTORY, inventory)
  },

  [SET_INVENTORY_DESKTOP]({ commit }, inventoryDesktop: boolean | null) {
    commit(_SET_INVENTORY_DESKTOP, inventoryDesktop)
  },

  [SET_WINDOW_WIDTH]({ commit }, windowWidth: number) {
    commit(SET_WINDOW_WIDTH, windowWidth)
  }
}
