import Vue from "vue"
import { GET_FAKE_ID } from "~/store/listing/getters"

export const _SET_RESPONSE = "setResponse"
export const _UPDATE_ITEM_SEARCH = "updateItemSearch"

export default {
  [_SET_RESPONSE](state: ItemSearch[], response: ItemSearch) {
    state.push(response)
  },
  [_UPDATE_ITEM_SEARCH](state: ItemSearch[], itemSearch: ItemSearch) {
    const key: string = itemSearch.key
    const item: Item = itemSearch.items[0]
    const itemsIndex = state.findIndex(itemSearch => itemSearch.key === key)
    const items: Item[] = state[itemsIndex].items
    const index: number = items.findIndex(
      it => it.fake_id === item.fake_id && it.location_id === item.location_id
    )

    item.fake_id = GET_FAKE_ID(item)
    Vue.set(state[itemsIndex].items, index, item)
  }
}
