import Vue from "vue"
import { ApiResource } from "~/scripts/api-resource"

// axios client config - apiService plugin will populate using setConfig() method
export interface Config {
  client: any
  baseUrl: string
}

const config: Config = {
  client: {},
  baseUrl: ""
}

export function setConfig(newConfig: Config) {
  config.client = newConfig.client
  config.baseUrl = newConfig.baseUrl ? newConfig.baseUrl : "/"
}

export interface RequestDataInterface {
  query?: any
  payload?: any
  headers?: any
}

export interface RequestInterface {
  method?: string
  url: string
  data?: RequestDataInterface
}

export default {
  // given the URL and PARAMS it will extend URL with query params
  createUrl(url: string, params?: any): string {
    if (!params) {
      params = {}
    }

    let result

    if (url.substr(0, 4) === "http") {
      result = url
    } else {
      result = config.baseUrl + url
    }

    const urlParams: string[] = []
    for (const key in params) {
      const value = params[key]
      if (Array.isArray(value)) {
        let arrayIndex = 0
        for (const val of value) {
          if (typeof val !== "object") {
            // params value is an array, we will create url like this: key[]=val1&key[]=val2
            urlParams.push(key + "[]=" + encodeURIComponent(val))
          } else {
            for (const prop in val) {
              if (Object.prototype.hasOwnProperty.call(val, prop)) {
                urlParams.push(
                  key + "[" + arrayIndex + "][" + prop + "]=" + encodeURIComponent(val[prop])
                )
              }
            }
          }
          arrayIndex++
        }
      } else if (value !== null && typeof value !== "undefined") {
        // handle properly as axios does, we dont need key=undefined or key=null in the url
        urlParams.push(key + "=" + encodeURIComponent(value))
      }
    }

    // no params need to be added to the url
    if (!urlParams.length) {
      return result
    }

    // add params to the url and return
    if (result.includes("?")) {
      // has question mark
      if (result.substring(result.length - 1) !== "&") {
        result += "&"
      }
    } else {
      // doesn't have question mark
      result += "?"
    }

    return result + urlParams.join("&")
  },

  async request(request: RequestInterface, apiResource?: ApiResource | null) {
    if (!request.method) {
      request.method = "GET"
    }

    let result

    // set initial data
    if (apiResource) {
      Vue.set(apiResource, "error", null)
      Vue.set(apiResource, "response", null)
      Vue.set(apiResource, "loading", true)
    }

    try {
      // prepare request data
      const payload = request.data && request.data.payload ? request.data.payload : null
      const query = request.data && request.data.query ? request.data.query : null
      const headers = request.data && request.data.headers ? request.data.headers : {}

      const axiosOptions = { headers }
      const url = this.createUrl(request.url, query)

      // do the request
      result = await config.client["$" + request.method.toLowerCase()](url, payload, axiosOptions)

      // update response
      if (apiResource) {
        Vue.set(apiResource, "response", result)
      }
    } catch (e) {
      if (apiResource) {
        Vue.set(apiResource, "loading", false)
        Vue.set(apiResource, "error", e)
      } else {
        // Sentry will log it if we are not capturing in the service or where we are calling the service
        throw e
      }
    }

    // loading completed
    if (apiResource) {
      Vue.set(apiResource, "loading", false)
    }

    return result
  }
}
