export const CATEGORY_V4_ID_FURNITURE = 1
export const CATEGORY_V4_ID_HOUSE_MOVE = 2
export const CATEGORY_V4_ID_SPECIALIST = 3
export const CATEGORY_V4_ID_CAR = 4
export const CATEGORY_V4_ID_BOAT = 5
export const CATEGORY_V4_ID_FREIGHT = 6
export const CATEGORY_V4_ID_INDUSTRIAL = 7
export const CATEGORY_V4_ID_LIVESTOCK = 8
export const CATEGORY_V4_ID_PIANO = 20
export const CATEGORY_V4_ID_MOTORCYCLE = 27
export const CATEGORY_V4_ID_VEHICLE_PARTS = 32
export const CATEGORY_V4_ID_CLEARANCE = 41
export const CATEGORY_V4_ID_OFFICE = 43
export const CATEGORY_V4_ID_PARCEL = 70
export const CATEGORY_V4_ID_MANPOWER = 71
export const CATEGORY_V4_ID_EBAY = 74

export const CATEGORY_ID_FURNITURE = "furniture"
export const CATEGORY_ID_HOUSE_MOVE = "house-move"
export const CATEGORY_ID_SPECIALIST = "specialist"
export const CATEGORY_ID_CAR = "car"
export const CATEGORY_ID_BOAT = "boat"
export const CATEGORY_ID_FREIGHT = "freight"
export const CATEGORY_ID_INDUSTRIAL = "industrial"
export const CATEGORY_ID_LIVESTOCK = "livestock"
export const CATEGORY_ID_PIANO = "piano"
export const CATEGORY_ID_MOTORCYCLE = "motorcycle"
export const CATEGORY_ID_VEHICLE_PARTS = "vehicle-parts"
export const CATEGORY_ID_CLEARANCE = "clearance"
export const CATEGORY_ID_OFFICE = "office"
export const CATEGORY_ID_PARCEL = "parcel"
export const CATEGORY_ID_MANPOWER = "manpower"
export const CATEGORY_ID_EBAY = "ebay"

export const CATEGORY_ID_MORE = "more"

export const CATEGORY_LIST: OptionString[] = [
  {
    label: "Home removals",
    name: "Home removals",
    value: CATEGORY_ID_HOUSE_MOVE
  },
  {
    label: "Furniture and appliances",
    name: "Furniture and appliances",
    value: CATEGORY_ID_FURNITURE
  },
  {
    label: "Cars and vehicles",
    name: "Cars and vehicles",
    value: CATEGORY_ID_CAR
  },
  {
    label: "Motorbikes",
    name: "Motorbikes",
    value: CATEGORY_ID_MOTORCYCLE
  },
  {
    label: "Piano delivery",
    name: "Piano delivery",
    value: CATEGORY_ID_PIANO
  },
  {
    label: "More services",
    name: "More services",
    value: CATEGORY_ID_MORE
  }
]

export const CATEGORIES: Category[] = [
  {
    id: CATEGORY_ID_FURNITURE,
    v4Id: CATEGORY_V4_ID_FURNITURE,
    slug: "furniture",
    name: "Furniture & Other Items",
    desc: "Tables, sofas, fridges...",
    icon: "category-" + CATEGORY_ID_FURNITURE
  },
  {
    id: CATEGORY_ID_HOUSE_MOVE,
    v4Id: CATEGORY_V4_ID_HOUSE_MOVE,
    slug: "house_move",
    name: "Home Removals",
    desc: "3 bed house, small studio...",
    icon: "category-" + CATEGORY_ID_HOUSE_MOVE
  },
  {
    id: CATEGORY_ID_CAR,
    v4Id: CATEGORY_V4_ID_CAR,
    slug: "car",
    name: "Cars & Vehicles",
    desc: "Ford Focus, tractors, caravans...",
    icon: "category-" + CATEGORY_ID_CAR
  },
  {
    id: CATEGORY_ID_MOTORCYCLE,
    v4Id: CATEGORY_V4_ID_MOTORCYCLE,
    slug: "motorcycle",
    name: "Motorbikes",
    desc: "Cruisers, sportsters, scooters...",
    icon: "category-" + CATEGORY_ID_MOTORCYCLE
  },
  {
    id: CATEGORY_ID_PIANO,
    v4Id: CATEGORY_V4_ID_PIANO,
    slug: "piano",
    name: "Pianos",
    desc: "Uprights, grands, keyboards...",
    icon: "category-" + CATEGORY_ID_PIANO
  },
  {
    id: CATEGORY_ID_EBAY,
    v4Id: CATEGORY_V4_ID_EBAY,
    slug: "ebay",
    name: "eBay Listings",
    desc: "Anything and everything...",
    icon: "ebay"
  },
  {
    id: CATEGORY_ID_PARCEL,
    v4Id: CATEGORY_V4_ID_PARCEL,
    slug: "parcel",
    name: "Parcels & Packages",
    desc: "Of all sizes & shapes...",
    icon: "category-package"
  },
  {
    id: CATEGORY_ID_SPECIALIST,
    v4Id: CATEGORY_V4_ID_SPECIALIST,
    slug: "specialist",
    name: "Specialist & Antiques",
    desc: "Glass cabinets, ornaments...",
    icon: "cabinet-2-1"
  },
  {
    id: CATEGORY_ID_VEHICLE_PARTS,
    v4Id: CATEGORY_V4_ID_VEHICLE_PARTS,
    slug: "vehicle_parts",
    name: "Vehicle Parts",
    desc: "Engines, tyres, body parts...",
    icon: "vehicle-parts-wheel"
  },
  {
    id: CATEGORY_ID_FREIGHT,
    v4Id: CATEGORY_V4_ID_FREIGHT,
    slug: "freight",
    name: "Freight",
    desc: "Pallets, shipments, loads...",
    icon: "freight"
  },
  {
    id: CATEGORY_ID_BOAT,
    v4Id: CATEGORY_V4_ID_BOAT,
    slug: "boats",
    name: "Boats",
    desc: "Yachts, fishing boats, canoes...",
    icon: "boat"
  },
  {
    id: CATEGORY_ID_OFFICE,
    v4Id: CATEGORY_V4_ID_OFFICE,
    slug: "office_removals",
    name: "Office Removals",
    desc: "Desks, computers, chairs...",
    icon: "chair-barber"
  },
  {
    id: CATEGORY_ID_CLEARANCE,
    v4Id: CATEGORY_V4_ID_CLEARANCE,
    slug: "clearance",
    name: "Clearance",
    desc: "Rubbish, waste disposal...",
    icon: "recycling"
  },
  {
    id: CATEGORY_ID_INDUSTRIAL,
    v4Id: CATEGORY_V4_ID_INDUSTRIAL,
    slug: "industrial",
    name: "Industrial",
    desc: "Machinery, materials...",
    icon: "industrial-building"
  },
  {
    id: CATEGORY_ID_LIVESTOCK,
    v4Id: CATEGORY_V4_ID_LIVESTOCK,
    slug: "livestock",
    name: "Livestock",
    desc: "Pets, farm animals...",
    icon: "paw-1"
  },
  {
    id: CATEGORY_ID_MANPOWER,
    v4Id: CATEGORY_V4_ID_MANPOWER,
    slug: "man_power",
    name: "Man Power Only",
    desc: "No van needed!",
    icon: "man-power"
  }
]
