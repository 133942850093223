export default (): LocationState => ({
  locationPins: [],
  locationInfo: {
    displayName: "",
    savings: 0,
    identifier: "",
    localExperts: 0,
    moves: 0,
    summaryHeading: "",
    summaryUsp1: "",
    summaryUsp2: "",
    summaryUsp3: ""
  }
})
