import { FURNITURE_OTHER_TYPE_DESKTOP } from "~/constants/item"

export const GET_ITEMS = "getItems"
export const GET_ITEMS_WITH_CUSTOM_ITEM = "getItemsWithCustomItem"

export default {
  [GET_ITEMS]: (state: ItemCategory[]) => (categoryString: string) => {
    let items

    if ((items = state.find(itemCategory => itemCategory.key === categoryString))) {
      if (items && items.items !== undefined) {
        return items.items
      }

      return []
    } else {
      return []
    }
  },
  [GET_ITEMS_WITH_CUSTOM_ITEM]: (state: ItemCategory[]) => (categoryString: string) => {
    let items

    if ((items = state.find(itemCategory => itemCategory.key === categoryString))) {
      if (items && items.items !== undefined) {
        const result = items.items.slice()
        result.push(FURNITURE_OTHER_TYPE_DESKTOP)
        return result
      }

      return []
    } else {
      return []
    }
  }
}
