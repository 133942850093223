import Vue from "vue"
import { Store } from "vuex"

export const _SET_FW_TOKEN = "setFwToken"
export const _SET_USER = "setUser"

export default {
  [_SET_FW_TOKEN](state: Auth, token: string) {
    state.fw_token = token
    state.fw_token_timestamp = Math.floor(Date.now() / 1000)
  },
  [_SET_USER](state: Auth, user: Object) {
    state.user = user
  }
}
