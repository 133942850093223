export const _SET_MAP_LOCATIONS = "setMapLocations"
export const _SET_LOCATION_INFO = "setLocationInfo"

export default {
  [_SET_MAP_LOCATIONS](state: LocationState, locations: MapLocation[]) {
    state.locationPins = locations
  },
  [_SET_LOCATION_INFO](state: LocationState, locations: LocationInfo) {
    state.locationInfo = locations
  }
}
