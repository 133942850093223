import Vue from 'vue'

export function iterationCopy<T = any>(src: any): T {
  let target: any
  if (src === null) {
    target = src
  } else if (src instanceof Date) {
    target = new Date(src)
  } else if (typeof src === 'object') {
    if (Array.isArray(src)) {
      target = []

      for (let i = 0; i < src.length; i++) {
        if (src[i] instanceof Date) {
          ;(target as any[]).push(new Date(src[i]))
        } else if (typeof src[i] === 'object') {
          ;(target as any[]).push(iterationCopy(src[i]))
        } else {
          ;(target as any[]).push(src[i])
        }
      }
    } else {
      target = {}

      for (const prop in src) {
        if (Object.prototype.hasOwnProperty.call(src, prop)) {
          if (src[prop] instanceof Date) {
            target[prop] = new Date(src[prop])
          } else if (typeof src[prop] === 'object') {
            target[prop] = iterationCopy(src[prop])
          } else {
            target[prop] = src[prop]
          }
        }
      }
    }
  } else {
    target = src
  }

  return target
}

export function loopThroughObjectAndCompare(
  obj1: object | any[],
  obj2: object | any[],
  prop: string
): boolean {
  let isThereDifference = false
  const type1 = typeof obj1[prop]
  const type2 = typeof obj2[prop]

  if (
    (type1 !== 'object' && obj1[prop] !== obj2[prop]) ||
    (type1 === 'object' && type2 !== 'object') ||
    (type1 === 'object' &&
      obj1[prop] !== null &&
      (obj2[prop].length !== obj1[prop].length ||
        Object.keys(obj2[prop]).length !== Object.keys(obj1[prop]).length))
  ) {
    isThereDifference = true
  }

  if (!isThereDifference && type1 === 'object' && type1 === type2) {
    isThereDifference = objectCompare(obj1[prop], obj2[prop])
  }

  return isThereDifference
}

export function objectCompare(
  obj1: object | any[],
  obj2: object | any[]
): boolean {
  let isThereDifference = false

  for (const prop in obj1) {
    isThereDifference = loopThroughObjectAndCompare(obj1, obj2, prop)

    if (isThereDifference) {
      break
    }
  }

  if (!isThereDifference) {
    for (const prop2 in obj2) {
      isThereDifference = loopThroughObjectAndCompare(obj1, obj2, prop2)

      if (isThereDifference) {
        break
      }
    }
  }

  return isThereDifference
}
