import Vue from "vue"
import { Property } from "./state"

export const _SET_PROPERTY_TYPES = "setPropertyTypes"
export const _SET_PROPERTY_TYPES_HOUSE = "setPropertyTypesHouse"
export const _SET_PROPERTY_TYPES_FLAT = "setPropertyTypesFlat"
export const _SET_FLOORS = "setFloors"

export default {
  [_SET_PROPERTY_TYPES](state: Property, propertyTypes: any[]) {
    Vue.set(state, "propertyTypes", propertyTypes)
  },

  [_SET_PROPERTY_TYPES_HOUSE](state: Property, propertyTypes: any[]) {
    Vue.set(state, "propertyTypesHouse", propertyTypes)
  },

  [_SET_PROPERTY_TYPES_FLAT](state: Property, propertyTypes: any[]) {
    Vue.set(state, "propertyTypesFlat", propertyTypes)
  },

  [_SET_FLOORS](state: Property, floors: any[]) {
    Vue.set(state, "floors", floors)
  }
}
