import { SnowplowService } from "~/services/public/Snowplow"
export default ({ app }, inject) => {
  const buttons = document.querySelectorAll(".av-button")
  const snowplow = new SnowplowService()
  buttons.forEach(button => {
    button.addEventListener("click", () => {
      // @ts-ignore
      snowplow.trackButtonClick(button.textContent, button.href)
    })
  })

  const textLinks = document.querySelectorAll(".link-quote")
  textLinks.forEach(link => {
    link.addEventListener("click", () => {
      // @ts-ignore
      snowplow.trackLinkClick(link.firstChild.textContent, link.firstChild.href)
    })
  })
}
