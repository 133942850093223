import { ITEM_SEARCH_LIMIT } from "~/constants/item"

export const GET_ITEMS = "getItems"

export default {
  [GET_ITEMS]: (state: ItemSearch[]) => (searchString: string) => {
    let items

    if (
      searchString.length >= ITEM_SEARCH_LIMIT &&
      (items = state.find(itemSearch => itemSearch.key === searchString))
    ) {
      if (items && items.items !== undefined) {
        return items.items
      }

      return []
    } else {
      return []
    }
  }
}
