import Vue from "vue"
import { Store } from "vuex"
import { GET_FAKE_ID } from "~/store/listing/getters"

export const _ADD_ITEM = "addItem"
export const _UPDATE_ITEM = "updateItem"
export const _DELETE_ITEM = "deleteItem"
export const _SET_ITEMS = "setItems"
export const _SET_FIRST_ITEM = "setFirstItem"
export const _DELETE_ITEM_BY_INDEX = "deleteItemByIndex"
export const _CLEAR_ITEMS = "clearItems"
export const _SET_PICKUP_ADDRESS = "setPickupAddress"
export const _SET_DELIVERY_ADDRESS = "setDeliveryAddress"
export const _SET_PICKUP_PROPERTY_TYPE = "setPickupPropertyType"
export const _SET_DELIVERY_PROPERTY_TYPE = "setDeliveryPropertyType"
export const _SET_PICKUP_FLOOR = "setPickupFloor"
export const _SET_DELIVERY_FLOOR = "setDeliveryFloor"
export const _SET_CATEGORY = "setCategory"
export const _SET_LISTING = "setListing"

export default {
  [_ADD_ITEM](state: Listing, item: Item) {
    if (typeof item.quantity === "undefined") {
      item.quantity = 1
    }

    state.items.push(item)
    state.itemsInitial = false
  },

  [_UPDATE_ITEM](state: Listing, item: Item) {
    const index: number = state.items.findIndex(
      it => it.fake_id === item.fake_id && it.location_id === item.location_id
    )

    item.fake_id = GET_FAKE_ID(item)
    Vue.set(state.items, index, item)
  },

  [_DELETE_ITEM](state: Listing, item: Item) {
    state.items = state.items.filter(it => it.fake_id !== item.fake_id)
  },

  [_SET_FIRST_ITEM](state: Listing, item: Item) {
    Vue.set(state.items, 0, item)
  },

  [_DELETE_ITEM_BY_INDEX](state: Listing, index) {
    state.items.splice(index, 1)
  },

  [_CLEAR_ITEMS](state: Listing) {
    Vue.set(state, "items", [])
  },

  [_SET_ITEMS](state: Listing, items: Array<Item>) {
    Vue.set(state, "items", items)
  },

  [_SET_PICKUP_ADDRESS](state: Listing, address: Address | null) {
    state.pickup.address = address
  },
  [_SET_DELIVERY_ADDRESS](state: Listing, address: Address | null) {
    state.delivery.address = address
  },

  [_SET_PICKUP_PROPERTY_TYPE](state: Listing, propertyType: string) {
    Vue.set(state.pickup, "propertyType", propertyType)
  },

  [_SET_DELIVERY_PROPERTY_TYPE](state: Listing, propertyType: string) {
    Vue.set(state.delivery, "propertyType", propertyType)
  },

  [_SET_PICKUP_FLOOR](state: Listing, floor: string) {
    Vue.set(state.pickup, "floor", floor)
  },

  [_SET_DELIVERY_FLOOR](state: Listing, floor: string) {
    Vue.set(state.delivery, "floor", floor)
  },

  [_SET_CATEGORY](state: Listing, categoryId: string | null) {
    Vue.set(state, "category_id", categoryId)
  },

  [_SET_LISTING](state: Store<Listing>, listing: Listing) {
    for (const prop in state) {
      if (listing[prop] !== undefined) {
        Vue.set(state, prop, listing[prop])
      }
    }
  }
}
