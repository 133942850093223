import { _SET_RESPONSE } from "~/store/item-search/mutations"
import { searchItems } from "~/services/public/ItemService"
import { GET_FAKE_ID } from "~/store/listing/getters"
import { ITEM_SEARCH_LIMIT } from "~/constants/item"
import { findItemTypeByName } from "~/utils/item"

export const SEARCH_ITEMS = "searchItems"
export const INCREASE_ITEM_SEARCH = "increaseItemSearch"
export const DECREASE_ITEM_SEARCH = "decreaseItemSearch"

export default {
  async [SEARCH_ITEMS]({ state, commit }, searchParams) {
    if (searchParams.name.length >= ITEM_SEARCH_LIMIT) {
      const response = await searchItems(searchParams)

      const itemSearch: ItemSearch = {
        key: searchParams.name,
        items: response.map(item => {
          const realItem: any = Object.values(item.item_set)[0]

          return {
            item_id: realItem.item_id,
            name: realItem.name,
            original_width: realItem.original_width,
            original_depth: realItem.original_depth,
            original_height: realItem.original_height,
            original_weight: realItem.original_weight,
            dimension_unit: realItem.dimension_unit,
            weight_unit: realItem.weight_unit,
            fake_id: GET_FAKE_ID(realItem),
            quantity: 0,
            is_admin: realItem.is_admin,
            type: findItemTypeByName(realItem.name)
          }
        })
      }

      commit(_SET_RESPONSE, itemSearch)

      return itemSearch.items.length
    }
  }
}
