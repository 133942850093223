import md5 from "md5"

export const GET_ITEMS = "getItems"
export const GET_ITEMS_BY_FAKE_ID = "getItemsByFakeID"
export const GET_ITEMS_COUNT = "getItemsCount"
export const GET_UNIQUE_ITEMS_COUNT = "getUniqueItemsCount"
export const GET_FIRST_ITEM = "getFirstItem"
export const GET_ITEM_INDEX = "getItemIndex"
export const GET_ITEM = "getItem"
export const IS_VALID_PICKUP = "isValidPickup"
export const IS_VALID_DELIVERY = "isValidDelivery"
export const GET_PICKUP_ADDRESS = "getPickupAddress"
export const GET_DELIVERY_ADDRESS = "getDeliveryAddress"
export const GET_PICKUP_PROPERTY_TYPE = "getPickupPropertyType"
export const GET_DELIVERY_PROPERTY_TYPE = "getDeliveryPropertyType"
export const GET_PICKUP_FLOOR = "getPickupFloor"
export const GET_DELIVERY_FLOOR = "getDeliveryFloor"
export const GET_ROUTE = "getRoute"
export const GET_CATEGORY = "getCategory"
export const GET_LISTING = "getListing"
export const GET_FAKE_ID = item => {
  return md5(
    item.name +
      (item.original_width ? Number(item.original_width) : Number(item.width)) +
      (item.original_depth ? Number(item.original_depth) : Number(item.depth)) +
      (item.original_height ? Number(item.original_height) : Number(item.height)) +
      item.dimension_unit +
      (item.original_weight ? Number(item.original_weight) : Number(item.weight)) +
      item.weight_unit +
      (item.location_id || item.location_id == 0 ? item.location_id : "")
  )
}
export default {
  [GET_ITEMS]: (state: Listing): Item[] => state.items,
  [GET_ITEMS_BY_FAKE_ID]: (state: Listing): any => {
    const itemsByFakeId = {}
    state.items.forEach(item => {
      if (item.fake_id) itemsByFakeId[item.fake_id] = item
    })
    return itemsByFakeId
  },
  [GET_ITEMS_COUNT]: (state: Listing) =>
    state.itemsInitial
      ? null
      : state.items.map(item => item.quantity).reduce((sum, x) => sum! + (x as number), 0),
  [GET_UNIQUE_ITEMS_COUNT]: (state: Listing) =>
    state.itemsInitial ? null : state.items.reduce(sum => sum! + 1, 0),
  [GET_FIRST_ITEM]: (state: Listing) => state.items.length > 0 && state.items[0],
  [GET_ITEM_INDEX]:
    (state: Listing) =>
    (item: Item): number =>
      state.items.findIndex(it => it.fake_id === item.fake_id),
  [GET_ITEM]: (state: Listing) => (item: Item) =>
    state.items.find(it => it.fake_id === item.fake_id),
  [IS_VALID_PICKUP]: (state: Listing): boolean =>
    state.pickup !== null &&
    typeof state.pickup === "object" &&
    state.pickup.address !== null &&
    typeof state.pickup.address.coordinates === "object" &&
    typeof state.pickup.address.coordinates.lat === "number" &&
    typeof state.pickup.address.coordinates.lng === "number",
  [IS_VALID_DELIVERY]: (state: Listing): boolean =>
    state.delivery !== null &&
    typeof state.delivery === "object" &&
    state.delivery.address !== null &&
    typeof state.delivery.address.coordinates === "object" &&
    typeof state.delivery.address.coordinates.lat === "number" &&
    typeof state.delivery.address.coordinates.lng === "number",
  [GET_PICKUP_ADDRESS]: (state: Listing): Address | null => state.pickup.address,
  [GET_DELIVERY_ADDRESS]: (state: Listing): Address | null => state.delivery.address,
  [GET_PICKUP_PROPERTY_TYPE]: (state: Listing): string | null => state.pickup.propertyType,
  [GET_DELIVERY_PROPERTY_TYPE]: (state: Listing): string | null => state.delivery.propertyType,
  [GET_PICKUP_FLOOR]: (state: Listing): string | null => state.pickup.floor,
  [GET_DELIVERY_FLOOR]: (state: Listing): string | null => state.delivery.floor,
  [GET_ROUTE]: (state: Listing): string =>
    "" +
    (state.pickup.address?.postcode
      ? "From " + state.pickup.address?.postcode.split(" ")[0]
      : state.pickup.address?.city
      ? "From " + state.pickup.address?.city
      : "") +
    (state.delivery.address?.postcode
      ? " to " + state.delivery.address?.postcode.split(" ")[0]
      : state.delivery.address?.city
      ? " to  " + state.delivery.address?.city
      : ""),
  [GET_CATEGORY]: (state: Listing): CategoryID | null => state.category_id,
  [GET_LISTING]: (state: Listing): Listing | null => state
}
