import Vue from 'vue'

export const _SET_NODES = 'setNodes'
export const _SET_PAGE = 'setPage'
export const _SET_PAGE_INFO = 'setPageInfo'
export const _SET_BREADCRUMBS = 'setBreadcrumbs'
export const _SET_INVENTORY = 'setInventory'
export const _SET_INVENTORY_DESKTOP = 'setInventoryDesktop'
export const _WINDOW_WIDTH = 'setWindowWidth'

export default {
  [_SET_NODES](state, edges) {
    edges.forEach((edge, idx) => {
      Vue.set(state.nodes, idx, edge.node)
    })
  },

  [_SET_PAGE](state, page) {
    Vue.set(state, 'page', page)
  },

  [_SET_PAGE_INFO](state, pageInfo) {
    Vue.set(state, 'pageInfo', pageInfo)
  },

  [_SET_BREADCRUMBS](state, breadcrumbs) {
    Vue.set(state, 'breadcrumbs', breadcrumbs)
  },

  [_SET_INVENTORY](state, inventory) {
    Vue.set(state, 'inventory', inventory)
  },

  [_SET_INVENTORY_DESKTOP](state, inventoryDesktop) {
    Vue.set(state, 'inventoryDesktop', inventoryDesktop)
  },

  [_WINDOW_WIDTH](state, windowWidth) {
    Vue.set(state, 'windowWidth', windowWidth)
  }
}
